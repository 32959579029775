var _ivlSocketState;
var _messages;

function dialerWireEvents() {
    document.addEventListener("messageEvent", messageEventHandler, false);
    document.addEventListener("socketStatusEvent", socketStatusEventHandler, false);
    document.addEventListener("mediaStatusEvent", mediaStatusEventHandler, false);
    document.addEventListener("authenticationEvent", authenticationEventHandler, false);
    document.addEventListener("applicationMessageEvent", applicationMessageEventHandler, false);
}

function messageEventHandler(e) {
    //console.log(e.detail.time.toLocaleString() + ": " + e.detail.message);
}

function authenticationEventHandler(e) {

}

function socketStatusEventHandler(e) {
    for (const key in IVLSocketState) {
        if (IVLSocketState[key] == e.detail.status) {
            if (key == 'Disconnected') {
                IVLDisconnect();
            }
            break;
        }
    }
    _ivlSocketState = e.detail.status;
}

function mediaStatusEventHandler(e) {

}

function applicationMessageEventHandler(e) {
    //console.log('Received: ' + e.detail.message);
    _messages = _messages + e.detail.message + "\r\n";

    var d = JSON.parse(e.detail.message);
    var messageType = d.__type;
    var version = d.version;
    switch(messageType) {
        case "AuthenticateChallenge": {
            var msg = new Object();
            msg.__type = "Authenticate";
            msg.version = 1;
            msg.authtoken = localStorage.getItem("dialToken");
            msg.userExtension = "101";
            IVLSocketSendApplicationMessage(msg);
            break;
        }
    }
}

function Disconnect() {
    var msg = new Object();
    msg.__type = "Hangup";
    msg.version = 1;
    IVLSocketSendApplicationMessage(msg);
}

function Mute() {
    var msg = new Object();
    msg.__type = "Mute";
    msg.version = 1;
    IVLSocketSendApplicationMessage(msg);
}

function Hold() {
    var msg = new Object();
    msg.__type = "Hold";
    msg.version = 1;
    IVLSocketSendApplicationMessage(msg);
}

function Transfer(destination) {
    var msg = new Object();
    msg.__type = "TransferCall";
    msg.version = 1;
    msg.destination = destination;
    IVLSocketSendApplicationMessage(msg);
}
